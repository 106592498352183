import React, { useEffect, useState } from 'react';
import styles from './DynamicForm.module.css';
import InputField from "./ImputCustom/CustomInpurField";
import VerificationModal from "../VerificationModal/VerificationModal";
import {Checkbox, Link, styled} from "@mui/material";

export const CustomCheckbox = styled(Checkbox)(() => ({
    color: '#D0D4DA',
    padding: 0,
    '&.Mui-checked': {
        color: '#4A3DFD',
    },
    '& .MuiSvgIcon-root': {
        fontSize: '1.2rem',
    },
}));

const fieldsConfig = {
    phiz: [
        { label: 'ФИО', name: 'fullName', type: 'text' },
        { label: 'Телефон', name: 'phone', type: 'tel' },
        { label: 'Email', name: 'email', type: 'email' },
    ],
    individual: [
        { label: 'ФИО', name: 'fullName', type: 'text' },
        { label: 'ИНН', name: 'inn', type: 'text' },
        { label: 'Телефон', name: 'phone', type: 'tel' },
        { label: 'Email', name: 'email', type: 'email' },
    ],
    legal: [
        { label: 'Наименование', name: 'companyName', type: 'text' },
        { label: 'ИНН', name: 'inn', type: 'text', half: true },
        { label: 'КПП', name: 'kpp', type: 'text', half: true },
        { label: 'Телефон', name: 'phone', type: 'tel' },
        { label: 'Email', name: 'email', type: 'email' },
    ],
};

const DynamicForm = ({ type, tariffId, tariffName }) => {
    const [isFormValid, setIsFormValid] = useState(false);
    const [formData, setFormData] = useState({});
    const [isChecked, setIsChecked] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    useEffect(() => {
        const isEveryFieldFilled = fieldsConfig[type].every(field => formData[field.name]);
        setIsFormValid((type === 'individual' ? isChecked : true) && isEveryFieldFilled);
    }, [formData, isChecked, type]);

    const handleChange = (name) => (e) => {
        setFormData({ ...formData, [name]: e.target.value });
    };

    const handleOpenModal = (e) => {
        e.preventDefault();
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleCodeSubmit = (userId) => {
        handleSubmit(userId);
    };

    const handleSubmit = (userId) => {
        const url = `https://ukassa.agatha-tender.ru/ukassa/form?id_item=${tariffId}&user_id=${userId}&email=${formData.email}`;
        window.location.href = url;
    };

    const handleCheckboxChange = (e) => {
        const newIsChecked = !isChecked;
        setIsChecked(newIsChecked);
    };

    const fields = fieldsConfig[type] || [];

    return (
        <>
            <form onSubmit={handleOpenModal} className={`${styles.formContainer}`}>
                <span className={styles.formTitle}>Реквизиты для счета</span>
                <span className={styles.formSubTitle}>Кому выставить счет</span>
                {
                    fields.map((field, index) => {
                        const isHalfWidth = type === 'legal' && (field.name === 'inn' || field.name === 'kpp');
                        if (isHalfWidth) {
                            if (field.name === 'inn') {
                                const kppField = fieldsConfig[type].find(f => f.name === 'kpp');
                                return (
                                    <div key="inn-kpp-container" className={styles.flexRow}>
                                        <InputField
                                            key="inn"
                                            type={field.type}
                                            placeholder={field.label}
                                            name={field.name}
                                            value={formData[field.name] || ''}
                                            onChange={handleChange(field.name)}
                                            className={`${styles.inputField} ${styles.halfWidth}`}
                                        />
                                        <InputField
                                            key="kpp"
                                            type={kppField.type}
                                            placeholder={kppField.label}
                                            name={kppField.name}
                                            value={formData[kppField.name] || ''}
                                            onChange={handleChange(kppField.name)}
                                            className={`${styles.inputField} ${styles.halfWidth}`}
                                        />
                                    </div>
                                );
                            }
                            return null;
                        } else {
                            return (
                                <div key={index} className={styles.inputsContainer}>
                                    <InputField
                                        type={field.type}
                                        placeholder={field.label}
                                        name={field.name}
                                        value={formData[field.name] || ''}
                                        onChange={handleChange(field.name)}
                                        className={styles.inputField}
                                    />
                                </div>
                            );
                        }
                    })
                }
                <span className={styles.afterMail}>Адрес должен совпадать с адресом для вода в сервис</span>
                {type === 'individual' && (
                    <div className={styles.checkBoxContainer}>
                        <CustomCheckbox
                            checked={isChecked}
                            onChange={handleCheckboxChange}
                        />
                        <span className={styles.afterMail}>Я прочитал(а) и соглашаюсь с правилами и условиями публичной оферты</span>
                    </div>
                )}
                <div className={styles.bottomContainer}>
                    <button type="submit" className={styles.submitButton} disabled={!isFormValid} style={{ opacity: isFormValid ? 1 : 0.5 }}>Оплатить</button>
                    <span className={`${styles.alignCenter} ${styles.afterMail}`}>
                        Нажимая на кнопку Вы соглашаетесь на <Link href="https://storage.yandexcloud.net/test-avatar/files/%D0%A1%D0%BE%D0%B3%D0%BB%D0%B0%D1%81%D0%B8%D0%B5%20%D0%BD%D0%B0%20%D0%BE%D0%B1%D1%80%D0%B0%D0%B1%D0%BE%D1%82%D0%BA%D1%83%20%D0%9F%D0%94.pdf" target="_blank" className={styles.link}>обработку персональных данных</Link> и получение информационных сообщений.
                    </span>
                </div>
            </form>
            {isModalOpen && (
                <VerificationModal
                    formData={formData}
                    tariffName={tariffName}
                    email={formData.email}
                    onClose={handleCloseModal}
                    onSubmit={handleCodeSubmit}
                />
            )}
        </>
    );
};

export default DynamicForm;
