import React, {useState} from 'react';
import styles from './testPagePayment.module.css'
import HeaderT from "../../../components/HeaderT/HeaderT";
import CircleSelector from "../../../components/SegmentPart/Segment";
import MainInputsContainer from "../../../components/MainInputs/MainInputsContainer";
import FeedBackContainer from "../../../components/PaymentFeedBack/PaymentFeedback";
const Payment = () => {
    const [selectedType, setSelectedType] = useState('phiz');

    return (
        <div className={styles.appContainer}>
            <HeaderT/>
            <CircleSelector selected={selectedType} setSelected={setSelectedType}/>
            <MainInputsContainer selectedType={selectedType} />
            <FeedBackContainer/>
            <HeaderT/>
        </div>
    );
};

export default Payment;
