import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import './vendor/fonts/global.css';
import { ModalProvider } from './hook/useModal';
import WithAxiosInterceptors from './hoc/WithAxiosInterceptors';
import theme from "../src/vendor/theme"
import Payment from "./pages/PaymentPages/TestPagePayment/TestPage";
function App() {
    return (
            <ThemeProvider theme={theme}>
                <Router>
                    <ModalProvider>
                        <WithAxiosInterceptors>
                                <Routes>
                                    <Route path="/" element={<Payment/>}>
                                    </Route>
                                </Routes>
                        </WithAxiosInterceptors>
                    </ModalProvider>
                </Router>
            </ThemeProvider>
    );
}

export default App;
