import style from './ModalPayment.module.css'
export const Modal = ({ onClose }) => (
    <div className={style.modalBackdrop}>
        <div className={style.modalContent}>
            <div className={style.modalHeading}>Отправлено</div>
            <div className={style.modalDesc}>Мы приняли ваше сообщение и скоро свяжемся с Вами!</div>
            <div className={style.sendButton} onClick={onClose}>Закрыть</div>
        </div>
    </div>
)
