import React, { useEffect, useState } from 'react'
import styles from '../Tarrifs/Auditory.module.css'
import style from '../InputForm/TextAreaCustom/TextAreaCutom.module.css'
import InputField from '../InputForm/ImputCustom/CustomInpurField'
import TextAreaField from '../InputForm/TextAreaCustom/TextAreaCustom'
import axios from 'axios'
import {Modal} from "../ModalPayment/ModalPayment";
import Circle from "../Circle/Circle";
import stylesC from "../SegmentPart/Circle.module.css";
import f2 from "../../common/HeaderPeach.png";
import f1 from "../../common/HeaderBlue.webp";
const FeedBackContainer = () => {
    const initialFormData = {
        fullName: '',
        email: '',
        phone: '',
        question: ''
    }
    const [formData, setFormData] = useState(initialFormData)
    const [isModalOpen, setModalOpen] = useState(false)
    useEffect(() => {
        if (isModalOpen) {
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.overflow = 'unset'
        }

        return () => {
            document.body.style.overflow = 'unset'
        }
    }, [isModalOpen])

    const [formTouched, setFormTouched] = useState({
        fullName: false,
        email: false,
        phone: false,
        question: false
    })

    const [formValid, setFormValid] = useState(false)

    useEffect(() => {
        const isValid = !!(formData.fullName && formData.email && formData.phone && formData.question)
        setFormValid(isValid)
    }, [formData])

    const handleBlur = (field) => {
        setFormTouched({ ...formTouched, [field]: true })
    }
    const checkError = (field) => formTouched[field] && !formData[field]

    const sendFeedback = async (formData) => {
        try {
            await axios.post('https://feedback.agatha-tender.ru/v1/task', {
                email: formData.email,
                text: formData.question,
                phone: formData.phone,
                fio: formData.fullName
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            setModalOpen(true)
            setFormData(initialFormData)
            setFormTouched({
                fullName: false,
                email: false,
                phone: false,
                question: false
            })
        } catch (error) {
            console.error('Ошибка при отправке данных:', error)
        }
    }
    const handleSubmit = (event) => {
        event.preventDefault()
        sendFeedback(formData)
    }

    const formatPhoneNumber = (value) => {
        if (!value.startsWith('+7')) {
            value = '+7' + value
        }

        let cleaned = '+7' + value.slice(2).replace(/\D/g, '')
        cleaned = cleaned.slice(0, 12)
        const match = cleaned.match(/^(\+7)(\d{3})(\d{3})(\d{2})(\d{2})$/)
        if (match) {
            return `${match[1]} ${match[2]} ${match[3]} ${match[4]} ${match[5]}`
        }

        return value.slice(0, 16)
    }

    const handlePhoneInputChange = (
        event
    ) => {
        const formattedPhoneNumber = formatPhoneNumber(event.target.value)
        setFormData({
            ...formData,
            phone: formattedPhoneNumber
        })
    }
    const handleInputChange = (
        event
    ) => {
        const { name, value } = event.target
        setFormData({
            ...formData,
            [name]: value
        })
    }

    return (
        <>
            <div className={`${styles.auditoryContent} ${styles.additionalMargin}`}>
                <Circle className={`${stylesC.gradientPeach} ${stylesC.rightCircleBottom}`} src={f2} alt='f2'/>
                <Circle className={`${stylesC.gradientCircleBlue} ${stylesC.LeftCircleBottom}`} src={f1} alt='f1'/>
                <div className={style.contactFormContainer}>
                    <h2 className={style.headerText}>Если возникли вопросы</h2>
                    <form className={style.inputForm} onSubmit={handleSubmit}>
                        <InputField
                            type="text"
                            placeholder="ФИО"
                            name="fullName"
                            value={formData.fullName}
                            onChange={handleInputChange}
                            onBlur={() => handleBlur('fullName')}
                            className={style.inputField}
                            isError={checkError('fullName')}
                        />
                        <div className={style.emailContainer}>
                            <InputField
                                type="email"
                                placeholder="Email"
                                name="email"
                                value={formData.email}
                                onChange={handleInputChange}
                                className={style.inputField}
                                onBlur={() => handleBlur('email')}
                                isError={checkError('email')}
                            />
                            <InputField
                                type="text"
                                placeholder="Телефон"
                                name="phone"
                                value={formData.phone}
                                onChange={handlePhoneInputChange}
                                className={style.inputField}
                                onBlur={() => handleBlur('phone')}
                                isError={checkError('phone')}
                            />
                        </div>
                        <TextAreaField
                            placeholder="Напишите нам свой вопрос"
                            name="question"
                            value={formData.question}
                            onChange={handleInputChange}
                            className={style.textAreaField}
                            maxLength={1000}
                            onBlur={() => handleBlur('question')}
                            isError={checkError('question')}
                        />
                        <span className={style.charLimitText}>Количество знаков не более 1000</span>
                        <div className={style.emailContainer}>
                            <button type="submit" className={style.submitButton} disabled={!formValid} >
                                Отправить
                            </button>
                            <div className={style.disclaimerWrapper}>
              <span className={style.disclaimerText}>
                Ввод номера телефона подтверждает ваше согласие с{' '}
              </span>
                                <a
                                    href="https://storage.yandexcloud.net/test-avatar/files/%D0%9F%D0%BE%D0%BB%D0%B8%D1%82%D0%B8%D0%BA%D0%B0_%D0%BA%D0%BE%D0%BD%D1%84%D0%B8%D0%B4%D0%B5%D0%BD%D1%86%D0%B8%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D1%81%D1%82%D0%B8.pdf"
                                    target="_blank"
                                    rel="noopener noreferrer nofollow"
                                    className={style.termsLink}
                                    style={{ textDecoration: 'none', cursor: 'pointer' }}
                                >
                                    условиями передачи информации
                                </a>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            {isModalOpen && <Modal onClose={() => setModalOpen(false)} />}
        </>
    )
}

export default FeedBackContainer
